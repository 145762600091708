@font-face {
  font-family: 'Nunito Sans';
  src: url('../assets/fonts/NunitoSans-Regular.woff'),
    url('../assets/fonts/NunitoSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../assets/fonts/NunitoSans-ExtraBold.woff'),
    url('../assets/fonts/NunitoSans-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../assets/fonts/AktivGroteskCorp.eot');
  src: local('AktivGroteskCorp'), local('AktivGroteskCorp'),
  url('../assets/fonts/AktivGroteskCorp.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/AktivGroteskCorp.woff') format('woff'),
  url('../assets/fonts/AktivGroteskCorp.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../assets/fonts/AktivGroteskCorp-Bold.eot');
  src: local('AktivGroteskCorp-Bold'), local('AktivGroteskCorp-Bold'),
  url('../assets/fonts/AktivGroteskCorp-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/AktivGroteskCorp-Bold.woff') format('woff'),
  url('../assets/fonts/AktivGroteskCorp-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../assets/fonts/AktivGroteskCorp-Light.eot');
  src: local('AktivGroteskCorp-Light'), local('AktivGroteskCorp-Light'),
  url('../assets/fonts/AktivGroteskCorp-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/AktivGroteskCorp-Light.woff') format('woff'),
  url('../assets/fonts/AktivGroteskCorp-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp Medium';
  src: url('../assets/fonts/AktivGroteskCorp-Medium.eot');
  src: local('AktivGroteskCorp-Medium'), local('AktivGroteskCorp-Medium'),
  url('../assets/fonts/AktivGroteskCorp-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/AktivGroteskCorp-Medium.woff') format('woff'),
  url('../assets/fonts/AktivGroteskCorp-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../assets/fonts/AktivGroteskCorp.eot');
  src: local('AktivGroteskCorp'), local('AktivGroteskCorp'),
  url('../assets/fonts/AktivGroteskCorp.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/AktivGroteskCorp.woff') format('woff'),
  url('../assets/fonts/AktivGroteskCorp.ttf') format('truetype');
  font-style: normal;
}
//new fonts
@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat-Bold'), local('Montserrat-Bold'),
  url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'), local('Montserrat-Regular'),
  url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat-Medium'), local('Montserrat-Medium'),
  url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
}

