@import '../../../styles/variables';

.left-sidebar {
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $lite-gray;
  position: relative;

  .bottom-buttons {
    margin-top: auto;
    margin-bottom: 0;
  }

  .info-tooltip{
    z-index: 1000;
    top: 59rem;
    left: 1.8rem;
  }

  .sidebar-logo {
    margin-left: 3.3rem;
    margin-bottom: 4.4rem;
    display: block;

    transition: margin-left 0.3s ease-in-out;
  }

  .tabs_wrapper {
    width: 100%;
    .wrapper__card-link {
      padding-left: 0;
      padding-right: 0;
    }
    .card-link__icon {
      left: 1.8rem;
    }
  }

  .wrapper__card-link {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    transition: padding 0.3s ease-in-out;
  }

  .card-link__icon {
    position: absolute;
    line-height: 8rem;
    left: 3.2rem;

    transition: left 0.3s ease-in-out;
  }

  .card-link {
    min-width: 100%;

    span {
      margin-left: 5.6rem;
      transition: opacity 0.3s ease-in-out;
    }
  }
}

.left-sidebar:not(.sidebar_open) {
  .wrapper__card-link {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .tabs_wrapper {
    width: 100%;
    .wrapper__card-link {
      padding-left: 0;
      padding-right: 0;
    }
    .card-link__icon {
      left: 2rem;
    }
  }

  .card-link  {
    position: relative;
    span {
      opacity: 0;
    }
  }

  .card-link__icon {
    position: absolute;
    line-height: 8rem;
    left: 2.5rem;
  }

  .sidebar-logo {
    margin-left: 2.5rem;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 8rem;
  padding: $indent_m 0 $indent_sm;
  box-shadow: 1px 0px 0px #F2F2F2, 0px 4px 14px rgba(0, 0, 0, 0.02);
  z-index: 2001;
  &_top__main {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: relative;
    .btn {
      margin-right: $indent_sm;
      cursor: pointer;
      visibility: hidden;
      transition: visibility 0.3s;
      &:hover,
      &_active {
        svg {
          path {
            fill: $violet;
            fill-opacity: 1;
          }
        }
        &::after {
          background-color: $light-blue;
        }
      }
      &_active {
        &::after {
          opacity: 1;
        }
      }
      &_have-notification {
        position: relative;
      }
    }
  }
  &__logo {
    width: 8rem;
    min-width: 8rem;
    display: flex;
    justify-content: center;
    &_have-notification {
      &::before {
        content: '';
        display: block;
        width: 0.6rem;
        height: 0.6rem;
        background-color: $dark-red;
        border-radius: 50%;
        position: absolute;
        left: 3.7rem;
        bottom: 2.4rem;
      }
    }
  }
  .pop-up__wrapper {
    padding: $indent_s 0;
    left: $indent_s;
    width: 22.8rem;
    bottom: 11rem;
    .item-link {
      display: flex;
      align-items: center;
      padding: $indent_s;
      color: $black-bold;
      font-family: 'Montserrat Medium';
      cursor: pointer;
      word-break: break-all;
      svg {
        margin-right: 1rem;
      }
      &:hover {
        background-color: $light-violet;
      }
      &_active {
        background-color: $light-blue;
        &:hover {
          background-color: $hover-light-blue;
        }
      }
    }
  }
  &_open {
    position: relative;
    .sidebar_top__main {
      .btn {
        visibility: initial;
      }
    }
    .sidebar__logo {
      &_have-notification {
        &::before {
          display: none;
        }
      }
    }
    .sidebar {
      &_top {
        &__notification {
          &-count {
            opacity: 1;
          }
        }
      }
    }
  }
  &_disabled {
    .wrapper__card-link {
      cursor: initial;
      pointer-events:none;
    }
    .tabs_wrapper {
      display: none !important;
    }
    .card-link {
      pointer-events:none;
      cursor: initial;
      &_active {
        background-color: transparent;
        span {
          color: $border-color !important;
        }
        path {
          fill: $border-color !important;
        }
      }
      span {
        color: $border-color !important;
      }
      path {
        fill: $border-color !important;
      }
      .chevron {
        display: none;
      }
    }
    &_admin {
      .wrapper__card-link {
        &:nth-child(n + 4) {
          cursor: initial;
          pointer-events:none;
          .card-link {
            pointer-events:none;
            cursor: initial;
            &_active {
              background-color: transparent;
              span {
                color: $border-color !important;
              }
              path {
                fill: $border-color !important;
              }
            }
            span {
              color: $border-color !important;
            }
            path {
              fill: $border-color !important;
            }
            .chevron {
              display: none;
            }
          }
        }
      }
    }
  }
  &_top {
    flex-grow: 1;
    overflow: hidden;
    padding-top: 1rem;

    &__notification {
      position: relative;
      margin-right: 5rem;

      &-count {
        color: $white;
        background-color: $dark-red;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-family: $font-family-bold;
        position: absolute;
        right: 1.3rem;
        top: -1.3rem;
        opacity: 0;
        transition: opacity 3ms;
      }
    }
  }
}
