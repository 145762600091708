@import '../../../styles/variables.scss';

.custom-textarea {
  position: relative;
  min-height: 11.6rem;
  border: $borderStyle;
  border-radius: $borderRadius;
  box-sizing: border-box;
  padding: $indent_s $indent_s 3rem;

  .text-area-label {
    position: absolute;
    top: 0;
    margin-top: 2.5rem;
    left: 1.6rem;
    transform: translateY(-50%);
    transition: all 0.2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 6rem);
  }

  &.move-placeholder {
    .text-area-label {
      left: 1.6rem;
      width: auto;
      background-color: #fff;
      font-size: 1.2rem;
      line-height: 1.4rem;
      top: 0;
      margin-top: 0;
      padding: 0 0.4rem;
      background: linear-gradient(to top, inherit 50%, white 30%);
    }
  }

  &_error.move-placeholder .text-area-label{
    color: $red;
  }

  .hover-child {
    cursor: pointer;
  }

  &_focus {
    border-color: $violet !important;
    outline: none;
  } 
  &_disabled {
    background: #f5f5f5;
    color: rgba(0,0,0,.25);
    cursor: not-allowed;
    border: none;
    textarea {
      cursor: not-allowed;
    }
    &.move-placeholder .text-area-label {
      background: linear-gradient(to top, #f5f5f5 50%, white 30%);
    }

    .text-area-label {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  textarea {
    border: none;
    width: 100%; 
    background-color: inherit;
    font-size: $font-size_xs;
    line-height: $font-size_xs;
    resize: none;
    &::placeholder{
      color: $dark-gray;
    } 
    &:focus {
      border-color: $violet;
      outline: none;
    } 
  }
  &__count-symbol {
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: $dark-gray;
    text-align: end;
    position: absolute;
    bottom: $indent_s;
    left: $indent_s;
  } 
  &_error {
    border-color: $red !important;
  }
  &__modify{
    width: 100%;
  }
  .input__error {
    bottom: -1.5rem;
    top: auto;
  }
  &__dropdown-list {
    position: absolute;
    background-color: $white;
    z-index: 5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: auto;
    max-height: 30rem;
    top: 100%;
    left: 0;
    span {
      display: inline-block;
      padding: $indent-s;
      &:hover {
        background-color: $light-gray-some-bg;
      }
    }
  }
}