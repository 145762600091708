@import '../../../styles/variables';

.app-notifications {
  &.hidden{
    display: none;
  }
  &.pop-up__wrapper {
    top: 5.8rem;
    left: calc(100% - 6rem) !important;
    width: 45rem !important;
    height: fit-content;
    padding: $indent_s !important;
    padding-top: 0 !important;
    overflow: hidden;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.6rem;
    padding: 0 $indent_xs;
    .count-new-notification {
      display: flex;
      align-items: center;
      color: $disabledColorText;
      svg {
        margin-left: $indent_ssm;
      }
    }
  }
  &__body {
    overflow: auto;
    max-height: 60rem;
  }
  &__date {
    text-align: center;
    margin-bottom: $indent_ssm;
    color: $dark-gray;
  }
}