@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.profile-button {
  @include row-y-center;
  height: 7.4rem;
  margin: 1.6rem 1.6rem -1.6rem;
  margin-top: auto;
  padding: 1.6rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;

  transition: all 0.3s ease-in-out;

  &__info {
    width: 15rem;
    overflow: hidden;
    margin-left: 2.4rem;
    margin-right: auto;
    overflow: hidden;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    .name,
    .company {
      text-overflow: ellipsis;
      text-align: start;
      overflow: hidden;
      white-space: nowrap;
    }

    .name {
      font-family: $font-family-bold;
      margin-bottom: 0.8rem;
    }
  }

  .svg-menu {
    margin-left: auto;
    flex-shrink: 0;
    position: absolute;
    left: calc(100% - 3rem);
    transition: all 0.5s ease-in-out;
  }

  &.close {
    margin-left: 0.6rem;
    margin-right: 0.6rem;

    .profile-button__info {
      width: 0;
      margin-left: 0;
      opacity: 0;
    }

    .svg-menu {
      transform: scale(0);
      opacity: 0;
    }
  }

  .profile-popup {
    position: absolute;
    left: 1.6rem;
    top: -14.5rem;
    display: flex;
    flex-direction: column;
    padding: 1.6rem 0;
    width: 22.8rem;
    background-color: #fff;
    border: 0.1rem solid rgba(0, 0, 0, .05);
    border-radius: 0.8rem;
    box-shadow: $boxShadow;
    z-index: 9000;

    transform: scale(0);

    transition: all 0.1s;


    &_btn {
      display: flex;
      align-items: center;
      padding: 1.6rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: $light-violet;
      }
      svg{
        margin-right: 1rem;
      }
    }

    &.open{
      transform: scale(1);
    }
  }
}