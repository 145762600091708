@import "../../../../../(deprecated)/styles/variables";

.main-layout {
  display: flex;

  &__work-flow {
    display: flex;
    flex-direction: column;
    width: calc(100% - 8rem);
    min-width: calc(100% - 8rem);
    background-color: $light-violet;
    box-sizing: border-box;
    transition: all 0.5s;

    &:has(.profile-page__content-wrapper) {
      background-color: #ffffff;
    }

    &_open-sidebar {
      width: calc(100% - 28rem);
      min-width: calc(100% - 28rem);
    }

    .work-flow__wrapper {
      padding: 3.4rem 2.2rem 3.4rem $indent_m;
      box-sizing: border-box;
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 100%;
      display: flex;
    }
  }
}

#popup-container {
  position: relative;
  transform: scale(1.25);
}

