@import '../../../../styles/variables';

.app-notification-card {
  border-radius: $borderRadius;
  border: $border-bottom;
  padding: $indent_s;
  display: block;
  cursor: pointer;
  color: initial;

  &__header {
    display: flex;
    align-items: center;
    font-family: "Nunito Sans";
    font-weight: 800;
    color: #333333;
    margin-bottom: $indent_s;

    svg {
      margin-right: $indent_xs;
    }
  }

  &__body {
    display: flex;
    font-size: $font-size-mobile_s;
    line-height: $font-size_xs;
    align-items: flex-end;
    justify-content: space-between;

    .main-info {
      margin-right: $indent_xs;

      &__title {
        display: block;
        color: $black-bold;
        font-family: $font-family-bold;
        margin-bottom: $indent_xs;
      }

      &__body {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        color: $disabledColorText;
        max-width: 32rem;
      }
    }
  }

  &__time {
    font-size: $font-size-mobile_s;
    line-height: $font-size_xs;
    color: $disabledColorText;
  }

  &:not(:last-child) {
    margin-bottom: $indent_ssm;
  }

  &_is-readed {
    background-color: $light-violet;
    border-color: transparent;

    .app-notification-card {
      &__header {
        color: $dark-gray;   
      }
    }
  }
}