@import "../../style/variables.scss";

.button {
  font-family: $font-family-bold;
  height: $buttonHeight;
  min-height: $buttonHeight;
  display: flex;
  align-items: center;
  padding: 0 $indent_sm;
  font-weight: bold;
  text-align: center;
  border-radius: 1rem;
  border: $borderStyle;
  font-size: $font-size_xs;
  line-height: 1.8rem;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: $violet;
  border-color: $violet;
  color: $white;
  white-space: nowrap;
  transition: all 0.2s ease;

  &:hover,
  &:active {
    background: $background-hover-secondary;

    &.button_small.button_secondary {
      background: transparent !important;
      color: $violet;

      .button_icon path {
        fill: $violet;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $light-gray-some-bg;
    border-color: transparent;
    color: $gray;
    cursor: not-allowed;
  }

  &_secondary {
    background-color: $white;
    border-color: $border-color;
    color: $violet;

    &:disabled {
      color: $gray;
    }

    &:hover {
      background-color: $light-violet;
    }
  }

  &_blue-ligth {
    background-color: $blue-ligth;
    border-color: $blue-ligth;
    color: $violet;

    &:hover {
      background-color: $blue-ligth-2;
      border-color: $blue-ligth-2;
    }
    &:disabled {
      border-color: $light-gray-some-bg;
      background-color: $light-gray-some-bg;
    }
  }

  &-withIcon {
    padding-left: $indent_s;

    &-without-text {
      padding: 0 $indent_s;

      .button_icon {
        margin-right: 0;
      }
    }
  }

  &_small {
    border-color: transparent;
    background: none;
    color: $violet;
    padding: $indent_s 0;
    padding: 0;

    &.button_secondary {
      border: 0;
      color: $dark-gray;
    }

    &:hover {
      background: transparent !important;
    }
  }

  &_only-text {
    position: relative;
    padding: 0;
    background-color: transparent;
    color: $violet;
    border: none;
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 3.2rem);
      z-index: -1;
      height: 100%;
      background-color: $light-violet;
      border-radius: $borderRadiusButton;
      opacity: 0;
    }

    &:hover {
      background: transparent;

      &::after {
        opacity: 1;
      }
    }
  }

  &_icon {
    margin-right: $indent_s;
    display: flex;
    align-items: center;
  }

  &_without-text {
    @extend .button_secondary;
    padding: $indent_s;

    .button_icon {
      margin: 0;
    }
  }
}
